import React from 'react';
import PageContentContainer from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import './styles.scss';
/* eslint-disable */
const Version: React.VoidFunctionComponent = ({
}) => {

  return (
    <PageContentContainer>
      <StickyPageHeader>
        <br/>
        <h3>Branch: demo/1.6.0-RC4</h3><br />
        <h3>Commit: b1989390</h3><br />
        <h3>Build Date: 2023-05-10T01:20:25Z</h3>
      </StickyPageHeader>
    </PageContentContainer>
  );
};

export default (Version);